<template>
    <div>
      <PurchaseList/>
    </div>
</template>

<script>
  import PurchaseList from '../components/app/purchases/purchase/PurchaseList'
  export default {
    name: 'Resources',
    components: { PurchaseList }
  }
</script>

<style scoped>

</style>
